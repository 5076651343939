export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    youtubeId: {
      type: String,
      required: true,
    },

    cover: {
      type: Object,
    },

    aspectRatio: {
      type: String,
      default: '16:9',
      validator: prop => ['1:1', '2.2:1', '2:3', '3:2', '13:16', '9:16', '16:9'].includes(prop),
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    gtm: {
      type: Object,
    },
  },

  data() {
    return {
      isLoaded: true,
      playing: false,
      player: null,
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll)
    })
  },

  methods: {
    start() {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      // Adiciona o evento de 'onYouTubeIframeAPIReady' ao window para garantir que a API esteja carregada
      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady
    },
    play(e) {
      if (this.gtm) {
        this.$gtm.push({
          event: 'noninteraction',
          event_category: 'CorporateWebsite:Home',
          event_action: this.gtm.show.action,
          event_label: this.gtm.show.label,
        })
      }
      this.isLoaded = !this.isLoaded
      this.playYoutube()
    },

    onYouTubeIframeAPIReady() {
      this.player = new YT.Player('player', {
        width: 1280,
        height: 720,
        border: '1px solid white',
        videoId: this.youtubeId,
        playerVars: {
          autoplay: 0,
          mute: 1,
          loop: 1,
          controls: 1,
          rel: 0,
          frameborder: 1,
        },
        style: {
          borderRadius: '13px',
          border: '2px solid white',
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      })

      var iframe = document.getElementById('player')
      iframe.setAttribute('frameborder', '1')
      iframe.style.borderRadius = '14px'
      iframe.style.border = '2px solid transparent'
    },

    playYoutube() {
      this.playing = !this.playing
      this.player.unMute()
      this.player.seekTo(0)
      this.setBestQuality()
    },

    onPlayerReady(event) {
      this.setBestQuality()
    },

    setBestQuality() {
      this.player.setPlaybackQuality('large')
    },

    onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.ENDED) {
        event.target.playVideo()
      }
    },

    handleScroll() {
      const targetElement = this.$refs.videoInline
      const elementRect = targetElement.getBoundingClientRect()
      if (
        elementRect.top >= 0 &&
        elementRect.left >= 0 &&
        elementRect.bottom - 500 <= (window.innerHeight || document.documentElement.clientHeight) &&
        elementRect.right <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        window.removeEventListener('scroll', this.handleScroll)
        this.start()
      }
    },
  },
}
